<template>
  <div class="floorManagement">
    <ds-header title="楼层管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="楼层名称：">
        <a-input v-model="searchForm.floorName" placeholder="请输入楼层名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="楼层ID：">
        <a-input v-model="searchForm.floorNo" placeholder="请输入楼层ID" style="width: 200px; margin-left: 14px" />
      </a-form-item>
      <a-form-item label="楼层状态：">
        <a-select
          v-model="searchForm.floorState"
          class="inputClass"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in floorType" :key="option.id" :value="option.id">{{
            option.value
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div
      style="padding-top: 15px"
      v-if="$checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorManagement.add)"
    >
      <a-button type="primary" @click="createFloor()" style="margin-left: 15px">新建楼层</a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a
            href="javascript:;"
            :disabled="record.floorState == true"
            :class="{ inputColor: record.floorState == true }"
            @click="edit(text.id)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorManagement.edit)"
          >
            编辑
          </a>
          <a
            href="javascript:;"
            @click="disableorEnabled(text)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorManagement.enable)"
          >
            {{ text.floorState ? "禁用" : "启用" }}
          </a>
          <a
            href="javascript:;"
            :disabled="record.floorState == true"
            :class="{ inputColor: record.floorState == true }"
            @click="deleteById(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.integralMallManagement.floorManagement.delete)"
          >
            删除
          </a>
        </span>
      </a-table>
    </div>
    <a-modal v-model="visibleFloor" :title="title" @ok="floorSure" :maskClosable="false" @cancel="cancel">
      <a-form-model layout="inline" ref="ruleForm" :model="params" :rules="rules">
        <a-form-model-item label="楼层名称：" prop="floorName">
          <a-input v-model.trim="params.floorName" :maxLength="10" placeholder="请输入楼层名称" class="inputClass" />
        </a-form-model-item>
        <a-form-model-item label="楼层排序：" prop="floorSort">
          <a-input v-model.trim="params.floorSort" placeholder="请输入楼层排序" class="inputClass" :maxLength="4" />
        </a-form-model-item>
        <a-form-model-item label="用户等级：" prop="userLevel">
          <a-checkbox-group v-model="params.userLevel">
            <a-checkbox value="0">普通用户</a-checkbox>
            <a-checkbox value="1">企业用户</a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item label="楼层状态：" required>
          <a-select
            v-model="params.floorState"
            class="inputClass"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="option in floorTypeOption" :key="option.state" :value="option.state">{{
              option.value
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import DSTable from "@/components/DSComponents/DSTable";
import debounce from "lodash/debounce";
import { getFloorList, floorAdd, floorInfo, floorUpdate, floorDisable, floorDelete } from '@/api/floorManagement'
import moment from "moment";

export default {
  name: "floorManagement",
  components: {
    DSTable,
    ConfirmBoxModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      searchForm: {
        floorName: "",
        floorNo: "",
        floorState: "",
      },
      title: "",
      id: "",
      params: {
        floorName: "",
        floorSort: "",
        floorState: "1",
        userLevel: [],
      },
      rules: {
        floorName: [
          {
            required: true,
            message: "请输入楼层名称",
            trigger: "blur",
          },
          { required: false, pattern: new RegExp(/^[^\s]*$/, "g"), message: "禁止输入空格" },
        ],
        floorSort: [
          {
            required: true,
            message: "请输入楼层排序",
            trigger: "blur",
          },
          { required: false, pattern: new RegExp(/^(0|\+?[1-9][0-9]*)$/, "g"), message: "只能输入数字" },
        ],

        userLevel: [
          {
            type: "array",
            required: true,
            message: "请选择用户等级",
            trigger: "change",
          },
        ],
      },
      floorTypeOption: [
        {
          state: "0",
          value: "禁用",
        },
        {
          state: "1",
          value: "启用",
        },
      ],
      floorType: [
        {
          id: "",
          value: "全部",
        },
        {
          id: "0",
          value: "禁用",
        },
        {
          id: "1",
          value: "启用",
        },
      ],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "楼层ID",
            dataIndex: "floorNo",
            key: "floorNo",
          },
          {
            title: "楼层名称",
            dataIndex: "floorName",
            key: "floorName",
          },
          {
            title: "楼层排序",
            dataIndex: "floorSort",
            key: "floorSort",
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
          },
          {
            title: "楼层状态",
            dataIndex: "floorState",
            key: "floorState",
            customRender(text) {
              return text ? "启用" : !text ? "禁用" : "-";
            },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      visibleFloor: false,
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.integralMallManagement.floorManagement.edit,
        this.$buttonPermissionsConfig.integralMallManagement.floorManagement.enable,
        this.$buttonPermissionsConfig.integralMallManagement.floorManagement.delete
      ],
    };
  },
  mounted() {
    this.getFloor();
  },
  methods: {
    // 删除
    async deleteById(record) {
      const confirmData = {
        title: "提示",
        content: "是否确认删除楼层？",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.delet(record.id);
    },
    // 删除
    async delet(id) {
      let data = {};
      data.id = id;
      const res = await floorDelete(data);
      if (res.code === "200") {
        this.$message.success("删除成功");
        this.getFloor();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取列表
    async getFloor() {
      let data = {};
      data.floorName = this.searchForm.floorName;
      data.floorNo = this.searchForm.floorNo;
      if (this.searchForm.floorState === "0") {
        var state = false;
      } else if (this.searchForm.floorState === "1") {
        var state = true;
      } else {
        var state = "";
      }
      data.floorState = state;
      data.pageNum = this.table.pagination.current;
      data.pageSize = this.table.pagination.pageSize;
      const res = await getFloorList(data);
      res.data.floorList.map((item) => {
        if (item.gmtCreate) {
          item.gmtCreate = moment(item.gmtCreate).format("YYYY-MM-DD HH:mm:ss");
        }
        if (item.gmtModified) {
          item.gmtModified = moment(item.gmtModified).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      this.table.dataInfo = res.data.floorList;
      this.table.pagination.total = res.data.total;
    },
    //查询
    search() {
      this.table.pagination.current = 1;
      this.getFloor();
    },
    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm.floorName = "";
      this.searchForm.floorNo = "";
      this.searchForm.floorState = "";
      this.getFloor();
    },
    // 新建楼层
    createFloor() {
      this.title = "新建楼层";
      this.params.floorName = "";
      this.params.floorSort = "";
      this.params.floorState = "1";
      this.params.userLevel = [];

      this.visibleFloor = true;
    },
    //编辑
    async edit(id) {
      this.title = "编辑楼层";
      this.id = id;
      let data = {};
      data.id = id;
      const res = await floorInfo(data);
      this.params.floorName = res.data.floorName;
      this.params.floorSort = res.data.floorSort;
      this.params.floorState = res.data.floorState ? "1" : "0";
      this.params.userLevel = res.data.userLevel.map(String);

      this.visibleFloor = true;
    },

    //禁用启用
    async disableorEnabled(text) {
      console.log(text);
      const confirmData = {
        title: "提示",
        content: `是否确认${text.floorState ? "禁用" : "启用"}？`,
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.floorDisable(text.id, text.floorState);
    },

    // 启用禁用
    async floorDisable(id, state) {
      let data = {};
      data.id = id;
      data.state = !state;
      const res = await floorDisable(data);
      if (res.code == "200") {
        this.$message.success(`${state ? "禁用" : "启用"}成功`);
        this.getFloor();
      } else {
        this.$message.error(res.msg);
      }
    },
    //添加
    floorSure: debounce(function () {
      if (this.params.floorState === "0") {
        var state = false;
      } else {
        var state = true;
      }
      let data = {};
      data.floorName = this.params.floorName;
      data.floorSort = this.params.floorSort;
      data.floorState = state;
      data.userLevel = this.params.userLevel;

      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.title === "新建楼层") {
            const res = await floorAdd(data);
            if (res.code === "200") {
              this.$message.success("新建成功");
              this.getFloor();
              this.$refs.ruleForm.resetFields();
              this.visibleFloor = false;
            } else {
              this.$message.warning(res.msg);
            }
          } else {
            data.id = this.id;
            const res = await floorUpdate(data);
            if (res.code === "200") {
              this.$message.success("编辑成功");
              this.getFloor();
              this.visibleFloor = false;
              this.$refs.ruleForm.resetFields();
            } else {
              this.$message.warning(res.msg);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 500),
    // 关闭蒙层的操作(取消校验)
    cancel() {
      this.$refs.ruleForm.resetFields();
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getFloor();
    },
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({width: "200px"}));
  }
};
</script>

<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
