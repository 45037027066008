import {
  fetchApi
} from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"

// 获取楼层列表
export const getFloorList = (data) => {
  return fetchApi(URL.FLOOR_LIST, data, 'get', 'params', true)
}

// 楼层新增
export const floorAdd = (data) => {
  return fetchApi(URL.FLOOR_ADD, data, 'post', 'data')
}

//楼层详情
export const floorInfo = (data) => {
  let param = {};
  return fetchApi(URL.FLOOR_INFO + '/' + data.id, param)
}

//修改楼层
export const floorUpdate = (data) => {
  return fetchApi(URL.FLOOR_UPDATE, data, 'PUT')
}

//启用禁用
export const floorDisable = (data) => {
  let param = {};
  return fetchApi(URL.FLOOR_DISABLE + '/' + data.id + '/' + data.state, param, 'PUT')
}

//楼层删除
export const floorDelete = (data) => {
  let param = {};
  return fetchApi(URL.FLOOR_DELETE + '/' + data.id, param, 'DELETE')
}